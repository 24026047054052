#freelancer-job-desktop {


    ion-list {
      background: none!important;
      background-color: none!important;

   }

   ion-item {
      // --background: red;
      // --border-color: #d8d8d8!important;
      // --border-width: 1px!important;
      --border-radius: 10px;
      cursor: pointer;

   }


   ion-item:hover {
      --background: #7A82EA;
      color: white;
      
   }

   // chat page
}

.scroll::-webkit-scrollbar {
   display: none;
}

.scroll{
   scrollbar-width: none;
}