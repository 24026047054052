#page-subscribe .background {
  height: 100%;
  width: 100%;
  background-color: #1e4464;
}

#page-subscribe .center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#page-subscribe .card{
  z-index: 100;
  padding: 0;
  margin: 0 auto;
}

#page-subscribe .sub-card{
  margin-top: -2em;
  padding-top: 2em;
}

#page-subscribe .paypal{
  color: #1e4464;
  font-size: 1em;
  font-weight: 700;
}

#page-subscribe h1 {
  color:white;
  font-size: 1.1em;
  font-weight: 400;
  margin: 1.5em;
}

#page-subscribe h2 {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 700;
  margin: 0; 
}

#page-subscribe h3 {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0; 
}

#page-subscribe h4 {
  font-size: 1em;
  font-weight: 400;
  margin: 0; 
}

#page-subscribe h5 {
  color:#869eaf;
  font-size: 0.8em;
  font-weight: 400;
  margin: 1em;
}

.ion-color-paypal {
  --ion-color-base: var(--ion-color-paypal);
  --ion-color-base-rgb: var(--ion-color-paypal-rgb);
  --ion-color-contrast: var(--ion-color-paypal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-paypal-contrast-rgb);
  --ion-color-shade: var(--ion-color-paypal-shade);
  --ion-color-tint: var(--ion-color-paypal-tint);
}