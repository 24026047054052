*{
   margin: 0;
}

*, body, html, h1, h2, h3, h4, h5, h6, p{
   font-family: fieldwork, sans-serif;
}

.hover:hover {
cursor: pointer;
}
ion-card {
   box-shadow: none;
}

.custom-alert img {

   width: 50%;
   height: 50%;
 
 }

 .custom-alert p{
    margin-top: 1rem;
    margin-bottom: 0;
 }


 body {
    background-color: #f2f2f2;
 }

 ion-card.ion-card-desktop {
   // box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.152);
   transition: box-shadow 150ms ease-in-out;
   
   &:hover{
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.19);
      // transform: scale(1.02);
      cursor: pointer;   
   }
 }

ion-content {
   --background: #F2F2F2;
 }

button {
   outline: none !important;
}



.desktop-hover {
    cursor: pointer;
}


