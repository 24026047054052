.freelancer-job-desktop ion-row:nth-child(1) {height: 100%;}



.freelancer-job-desktop {
   height: 100%;

h2 {
   margin: 0;
   padding: 0;
}

 ion-grid {
    height: 100%;
    padding: 0;

 }

 ion-grid ion-row {
    padding: 0;
    height: 100%;

 }

.toolbar{
   // padding-left: 2rem;
   margin-right: 2rem;
   // border-radius: 10px;

   .card {
      background-color: white;
      padding: 1rem 2rem;
      border-radius: 10px;
      height: 80vh;
      width: 100%;
   }

}
.content {
   // padding-left: 2rem;

   .card {
      background-color: white;
      padding: 1rem 2rem;
      border-radius: 10px;
      margin-bottom: 2rem;

     

      .info {
         display: flex;
      
         p{

            font-size: .7rem;
            padding-right: .7rem;
            color: #7d7d7d;
         
            margin-top: 0.4rem;
         
         }
      
      }
   }

}

.card .card-badges ion-badge {
   margin-right: .5rem;
   font-size: .8rem;
   font-weight: 500;
   text-transform: lowercase;
}

.card ion-avatar {
   width: 30px;
   height: 30px;
}


.card .card-client {
   display: flex;
   align-items: center;

   margin-top: 1rem;

   ion-avatar {
      margin-right: .7rem;
   }

   p { font-size: .7rem;}



}




.header-toolbar .active p {   
   color:#7A82EA;
   margin-bottom: -3px;
}



}